import { createRouter, createWebHistory } from 'vue-router'
import ENView from '../views/ENView.vue'

const routes = [
  {
    path: '/',
    name: 'en',
    component: ENView
  },
    {
        path: '/cn',
        name: 'cn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/CNView.vue')
    },
    {
        path: '/personal-data-policy',
        name: 'personal-data-policy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/ENPolicyView.vue')
    },
    {
        path: '/cn/personal-data-policy',
        name: 'personal-data-policy-cn',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/CNPolicyView.vue')
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
